import Axios from 'axios'
import { transformResponseToCamelCase } from 'packages/api'

const BASE_URL_IMG = 'https://s3-api-dev.florals24.ru/'
const headersFormData = {
  'Content-Type': 'multipart/form-data',
}
const imgEndpoint = {
  uploadFile: () => 'upload',
}

const axios = Axios.create({
  baseURL: BASE_URL_IMG,
  transformResponse: transformResponseToCamelCase,
  headers: headersFormData,
})

interface UploadResponse {
  object: string
}

const uploadFile = async (file: File) => {
  const { data } = await axios.request<UploadResponse>({
    method: 'POST',
    url: imgEndpoint.uploadFile(),
    data: { file },
  })

  return data
}

const uploadFiles = async (file: File) =>
  axios.request<UploadResponse>({
    method: 'POST',
    url: imgEndpoint.uploadFile(),
    data: { file },
  })

export const imgApi = {
  uploadFile,
  uploadFiles,
}

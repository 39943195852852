import { i18n } from 'config/i18n'

export const parametersCompanyAndSetting = () => [
  {
    id: 0,
    name: i18n.get('pro.employee.table.pointsSale'),
    navigate: {
      to: '/shops',
    },
  },
  {
    id: 1,
    name: i18n.get('pro.companySetting.settingCompany'),
    navigate: {
      to: '/settings-company',
    },
  },
  {
    id: 2,
    name: i18n.get('pro.employee.listEmployee'),
    navigate: {
      to: '/list-employee',
    },
  },
  {
    id: 3,
    name: i18n.get('pro.roles.rolesAndAccessRight'),
    navigate: {
      to: '/roles-list',
    },
  },
  {
    id: 4,
    name: i18n.get('manuals.title'),
    navigate: {
      to: '/manuals',
    },
  },
]

import { FC, useState } from 'react'

import * as Form from '@radix-ui/react-form'
import { Avatar, Flex, Separator, Text } from '@radix-ui/themes'
import { useNavigate } from '@tanstack/react-router'
import { useTranslate } from 'config/i18n'
import { AuthSteps } from 'pro/auth/interfaces'
import { useAuth } from 'pro/auth/store'
import { CompanySuggestion } from 'pro/companies/api/interfaces'
import { useCompanyId } from 'pro/companies/store'
import { Button } from 'ui/Button'

import classes from './CompaniesMenu.module.scss'

interface Props {
  getCompanyInfo: CompanySuggestion[] | []
  company?: CompanySuggestion
  setOpen: (open: boolean) => void
}

export const CompaniesMenu: FC<Props> = ({ getCompanyInfo, company }) => {
  const translate = useTranslate()
  const navigate = useNavigate()
  const { setAuthStep } = useAuth()
  const { setCompanyId } = useCompanyId()
  const [checkedCompanyId, setCheckedCompanyId] = useState<number | null>(Number(company ? company.id : null))

  const selectCompany = (id: number) => {
    setCheckedCompanyId(id)
    navigate({
      to: '/',
    })
    setCompanyId(id)
  }

  const createNewCompany = () => {
    setAuthStep(AuthSteps.CreateCompany)
    navigate({ to: '/create-company' })
  }

  return (
    <div className={classes.wrap}>
      <div className={classes.form}>
        <Flex className={classes.companies} direction="column" gap="6">
          {getCompanyInfo.map((itemCompany) => (
            <Flex
              align="center"
              className={classes.company}
              justify="between"
              key={itemCompany.id}
              onClick={() => selectCompany(itemCompany.id)}
            >
              <Flex gap="3">
                <Avatar fallback={itemCompany.name[0]} radius="medium" size="4" src={itemCompany.logo} />
                <div className={classes.inf}>
                  <Text className={classes.name} weight="medium">
                    {itemCompany.name.length <= 14 ? itemCompany.name : `${itemCompany.name.slice(0, 15)}...`}
                  </Text>
                  <Flex color="gray">
                    <Text className={classes.text} color="gray">
                      {itemCompany?.formOwnership === 'LLC'
                        ? translate('pro.auth.LLC')
                        : itemCompany?.formOwnership === 'IE'
                          ? translate('pro.auth.IE')
                          : ''}{' '}
                      • {itemCompany.name.length <= 14 ? itemCompany.name : `${itemCompany.name.slice(0, 15)}...`}
                    </Text>
                  </Flex>
                </div>
              </Flex>

              <label className={classes.customRadio}>
                <input checked={itemCompany.id === checkedCompanyId} name={classes.radioGroup} type="radio" />
                <span className={classes.checkmark} />
              </label>
            </Flex>
          ))}
        </Flex>

        <div className={classes.separatorBlock}>
          <Separator my="4" size="4" />
        </div>

        <div className={classes.groupedBlock}>
          <Form.Submit asChild>
            <Button className={classes.button} isLoading={false} onClick={createNewCompany} size="3" variant="soft">
              {translate('pro.auth.createNewCompany')}
            </Button>
          </Form.Submit>
        </div>
      </div>
    </div>
  )
}

import { FC, memo, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'

import * as Form from '@radix-ui/react-form'
import { Text } from '@radix-ui/themes'
import cx from 'clsx'
import { capitalize } from 'lodash'

import classes from './FormField.module.scss'

interface FormFieldProps {
  className?: string
  classNameLabel?: string
  name: string
  label?: string
  error?: FieldError
  children: ReactNode
  required?: boolean
  component?: ReactNode
  errorSize?: 'sm' | 'md' | 'lg'
  labelSize?: 'md' | 'lg'
}

export const FormField: FC<FormFieldProps> = memo(
  ({
    className,
    name,
    label,
    error,
    children,
    required,
    component,
    classNameLabel,
    errorSize = 'md',
    labelSize = 'md',
  }) => (
    <div>
      <Form.Field
        className={cx(className, { [classes.field]: typeof (children as any)?.props?.checked !== 'undefined' })}
        name={name}
      >
        {label && (
          <div className={cx(classNameLabel, classes[`labelSize${capitalize(labelSize)}`])}>
            <Form.Label>
              {label}
              {required ? ' *' : ''}
              {component}
            </Form.Label>
          </div>
        )}

        {children}
      </Form.Field>
      {error && (
        <Text className={cx(classes[`errorSize${capitalize(errorSize)}`])} color="red">
          {error.message}
        </Text>
      )}
    </div>
  ),
)

import { usePaginateQuery, useQueryClient } from 'packages/react-query'
import { useCompanyId } from 'pro/companies/store'
import { proListEmployeesApi } from 'pro/listEmployees/api/methods'

import { usersQuery } from './queryKeys'

export const useUsers = (filterName?: string, filterActive?: boolean) => {
  const queryClient = useQueryClient()
  const { companyId } = useCompanyId()

  const { isPending, error, data, pagination, currentPage } = usePaginateQuery({
    queryKey: [usersQuery, filterName, filterActive, companyId],
    queryFn: ({ pageParam }) =>
      proListEmployeesApi.getUsers({ page: pageParam }, String(companyId), filterName, filterActive),
    perPage: 50,
  })

  const clearUsers = () => {
    queryClient.removeQueries({ queryKey: [usersQuery] })
  }

  return {
    isLoading: isPending,
    error,
    users: data?.pages[currentPage]?.data ?? [],
    clearUsers,
    pagination,
  }
}
